<template lang="pug">
.settings-panel(:class="readable && 'grey'")
  .settings-panel__header(v-if="$slots.header")
    .title
      slot(name="header")
    .actions(v-if="$slots.headerActions")
      slot(name="headerActions")
  .settings-panel__content
    slot
  .settings-panel__comments(v-if="$slots.comments")
    slot(name="comments")
  .settings-panel__actions(v-if="$slots.actions")
    slot(name="actions")
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsPanel",
  props: {
    readable: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.settings-panel {
  display: flex;
  flex-flow: column;
  gap: 24px;

  border: 1px solid #e1e2e6;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 32px;
  background-color: var(--main-color-white);

  &.grey {
    background-color: #fafafa;
  }

  .settings-panel__header {
    display: flex;
    flex-flow: row;
    gap: 16px;
    font-size: 13px;
    line-height: 24px;
    align-items: center;

    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .actions {
      margin-left: auto;
    }
  }

  .settings-panel__content {
    display: flex;
    flex-flow: column;
    gap: 16px;
    height: 100%;
  }

  .settings-panel__actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  }

  .settings-panel__comments {
    display: flex;
    flex-flow: column;
    gap: 4px;

    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 860px) {
  .settings-panel {
    padding: 20px 24px;
  }
}
</style>
